import { useState, useEffect } from "react";
import { notifyError, notifySuccess } from "../../utils/toast";
import { patchRequest } from "../../helpers/requests";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Collapse from "@mui/material/Collapse";
import { Button, CardBody, Label, Input, Select } from "@windmill/react-ui";

function NurseProfile() {
  const [nurseProfile, setNurseProfile] = useState(null);
  const [openCardIndex, setOpenCardIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null);

  const locumShiftOptions = ["2-hr shift", "4-hr shift", "8-hr shift"];
  const nurseBandOptions = [5, 6, 7, 8];
  const travelDistances = [
    { label: "40", value: 40 },
    { label: "50", value: 50 },
    { label: "60", value: 60 },
    { label: "70", value: 70 },
    { label: "80", value: 80 },
    { label: "90", value: 90 },
    { label: "100", value: 100 },
    { label: ">100", value: 101 },
  ];
  
  const additionalSkillsOptions = [
    "ECG",
    "Vaccine",
    "Centrifuge",
    "Blood Pressure (Machine) for Home Visits",
    "Occupational Health",
    "Drug and Alcohol Screening (Urine)",
  ];

  useEffect(() => {
    const storedMeta = localStorage.getItem("user_metadata");
    if (storedMeta) {
      try {
        const parsed = JSON.parse(storedMeta);
        if (parsed?.user_id) {
          setUserId(parsed.user_id);
        }
      } catch (err) {
        console.error("Error parsing user_metadata from localStorage:", err);
      }
    }

    const storedNurseProfile = localStorage.getItem("nurse_profile");
    if (storedNurseProfile) {
      try {
        setNurseProfile(JSON.parse(storedNurseProfile));
      } catch (err) {
        console.error("Error parsing nurse_profile from localStorage:", err);
      }
    }
  }, []);

  const handleChange = (e) => {
    if (!nurseProfile) return;
    const { name, type, checked, value } = e.target;

    setNurseProfile((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleAdditionalSkillsChange = (e) => {
    if (!nurseProfile) return;

    const skill = e.target.value;
    let currentSkills = nurseProfile.additional_skills
      ? nurseProfile.additional_skills.split(",")
      : [];
    currentSkills = currentSkills.filter((s) => s.trim() !== "");

    if (e.target.checked) {
      if (!currentSkills.includes(skill)) {
        currentSkills.push(skill);
      }
    } else {
      currentSkills = currentSkills.filter((s) => s !== skill);
    }

    const updatedSkills = currentSkills.join(",");
    setNurseProfile((prev) => ({
      ...prev,
      additional_skills: updatedSkills,
    }));
  };

  const handleLocumEventChange = (e) => {
    if (!nurseProfile) return;

    const eventOption = e.target.value;
    let currentEvents = nurseProfile.locum_group_events
      ? nurseProfile.locum_group_events.split(",")
      : [];
    currentEvents = currentEvents.filter((evt) => evt.trim() !== "");

    if (e.target.checked) {
      if (!currentEvents.includes(eventOption)) {
        currentEvents.push(eventOption);
      }
    } else {
      currentEvents = currentEvents.filter((evt) => evt !== eventOption);
    }

    const updatedEvents = currentEvents.join(",");
    setNurseProfile((prev) => ({
      ...prev,
      locum_group_events: updatedEvents,
    }));
  };

  const hasLocumEvent = (option) => {
    if (!nurseProfile?.locum_group_events) return false;
    return nurseProfile.locum_group_events.split(",").includes(option);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (!userId || !nurseProfile) {
      notifyError("Cannot save profile.");
      return;
    }

    try {
      notifySuccess("Updating your profile...");
      setLoading(true);
      await patchRequest(`nurse-profile/${userId}`, nurseProfile);
      notifySuccess("Profile updated successfully!");
      localStorage.setItem("nurse_profile", JSON.stringify(nurseProfile));
    } catch (err) {
      console.error(err);
      notifyError("An error occurred while updating your profile");
    } finally {
      setLoading(false);
    }
  };

  const hasSkill = (skill) => {
    if (!nurseProfile?.additional_skills) return false;
    return nurseProfile.additional_skills.split(",").includes(skill);
  };

  return (
    <Card className="min-w-0 shadow-xs overflow-hidden bg-white mt-5 mb-5">
      <CardHeader
        title="Your Profile"
        subheader="View and edit your nurse profile information here."
      />
      <Collapse in={openCardIndex === 0} timeout="auto" unmountOnExit>
        <CardBody>
          <form onSubmit={handleSave}>
            <div className="grid grid-cols-4 gap-4">
              {/* PERSONAL INFORMATION */}
              <h2 className="col-span-4 text-lg font-semibold">
                Personal Information
              </h2>

              <div className="col-span-3 grid grid-cols-3 gap-4">
                <div>
                  <Label>
                    <span>Full Name</span>
                    <Input
                      name="full_name"
                      type="text"
                      value={nurseProfile?.full_name || ""}
                      onChange={handleChange}
                    />
                  </Label>
                </div>

                <div>
                  <Label>
                    <span>Phone Number</span>
                    <Input
                      name="phone_number"
                      type="text"
                      value={nurseProfile?.phone_number || ""}
                      onChange={handleChange}
                    />
                  </Label>
                </div>

                <div>
                  <Label>
                    <span>Personal Emails</span>
                    <Input
                      name="personal_emails"
                      type="email"
                      value={nurseProfile?.personal_emails || ""}
                      onChange={handleChange}
                    />
                  </Label>
                </div>

                <div className="col-span-3">
                  <Label>
                    <span>Address Line 1</span>
                    <Input
                      name="address_line1"
                      type="text"
                      value={nurseProfile?.address_line1 || ""}
                      onChange={handleChange}
                    />
                  </Label>
                </div>

                <div className="col-span-3">
                  <Label>
                    <span>Address Line 2</span>
                    <Input
                      name="address_line2"
                      type="text"
                      value={nurseProfile?.address_line2 || ""}
                      onChange={handleChange}
                    />
                  </Label>
                </div>

                <div>
                  <Label>
                    <span>City</span>
                    <Input
                      name="city"
                      type="text"
                      value={nurseProfile?.city || ""}
                      onChange={handleChange}
                    />
                  </Label>
                </div>

                <div>
                  <Label>
                    <span>Postcode</span>
                    <Input
                      name="postcode"
                      type="text"
                      value={nurseProfile?.postcode || ""}
                      onChange={handleChange}
                    />
                  </Label>
                </div>

                <div>
                  <Label>
                    <span>Country</span>
                    <Input
                      name="country"
                      type="text"
                      value={nurseProfile?.country || ""}
                      onChange={handleChange}
                    />
                  </Label>
                </div>
              </div>

              {/* PROFESSIONAL DETAILS */}
              <h2 className="col-span-full text-lg font-semibold">
                Professional Details
              </h2>

              <div className="col-span-1">
                <Label>
                  <span>Insurance Expiry Date</span>
                  <Input
                    name="insurance_expiry_date"
                    type="date"
                    value={nurseProfile?.insurance_expiry_date || ""}
                    onChange={handleChange}
                  />
                </Label>
              </div>

              <div className="col-span-1">
                <Label>
                  <span>Cover Amount per Claim</span>
                  <Input
                    name="insurance_cover_amount_per_claim"
                    type="number"
                    step="0.01"
                    value={nurseProfile?.insurance_cover_amount_per_claim || ""}
                    onChange={handleChange}
                  />
                </Label>
              </div>

              <div className="col-span-1">
                <Label>
                  <span>Travel Distance for Series Jobs</span>
                  <Select
                    name="travel_distance_for_series_jobs"
                    value={nurseProfile?.travel_distance_for_series_jobs ?? ""}
                    onChange={handleChange}
                  >
                    <option value="">Select Distance</option>
                    {travelDistances.map((dist) => (
                      <option key={dist.value} value={dist.value}>
                        {dist.label}
                      </option>
                    ))}
                  </Select>
                </Label>
              </div>

              <div className="col-span-1">
                <Label>
                  <span>Youngest Age Phlebotomy</span>
                  <Input
                    name="youngest_age_phlebotomy"
                    type="number"
                    value={nurseProfile?.youngest_age_phlebotomy || ""}
                    onChange={handleChange}
                  />
                </Label>
              </div>

              <div className="col-span-1">
                <Label>
                  <span>Years of Experience in Phlebotomy</span>
                  <Input
                    name="years_experience_phlebotomy"
                    type="number"
                    value={nurseProfile?.years_experience_phlebotomy || ""}
                    onChange={handleChange}
                  />
                </Label>
              </div>

              <div className="col-span-1">
                <Label>
                  <span>Nurse Band Number</span>
                  <Select
                    name="nurse_band_number"
                    value={nurseProfile?.nurse_band_number || ""}
                    onChange={handleChange}
                  >
                    <option value="">Select band</option>
                    {nurseBandOptions.map((band) => (
                      <option key={band} value={band}>
                        {band}
                      </option>
                    ))}
                  </Select>
                </Label>
              </div>

              {(nurseProfile?.allowed_to_event || nurseProfile?.allowed_to_locum) && (
                <div className="col-span-2">
                  <Label>
                    <span>Locum/Group Events</span>
                    <div className="flex items-center gap-4 mt-1">
                      {locumShiftOptions.map((shift) => (
                        <Label check key={shift} className="inline-flex items-center gap-2">
                          <Input
                            type="checkbox"
                            name="locum_group_events"
                            value={shift}
                            checked={hasLocumEvent(shift)}
                            onChange={handleLocumEventChange}
                          />
                          <span>{shift}</span>
                        </Label>
                      ))}
                    </div>
                  </Label>
                </div>
              )}

              <div className="col-span-full flex flex-wrap items-center space-x-4">
                <Label check>
                  <Input
                    type="checkbox"
                    name="had_insurance_claim_in_last_5_years"
                    checked={!!nurseProfile?.had_insurance_claim_in_last_5_years}
                    onChange={handleChange}
                  />
                  <span className="ml-2">
                    Had Insurance Claim in Last 5 Years
                  </span>
                </Label>
                <Label check>
                  <Input
                    type="checkbox"
                    name="have_vehicle"
                    checked={!!nurseProfile?.have_vehicle}
                    onChange={handleChange}
                  />
                  <span className="ml-2">Have Vehicle</span>
                </Label>
              </div>

              <h2 className="col-span-full text-lg font-semibold">
                Additional Skills
              </h2>
              <p className="col-span-full text-sm text-gray-600">
                Please tick all additional skills you have:
              </p>

              <div className="col-span-full grid grid-cols-1 gap-2">
                {additionalSkillsOptions.map((skill) => {
                  const isChecked = hasSkill(skill);
                  return (
                    <Label check key={skill}>
                      <Input
                        type="checkbox"
                        name="additional_skills"
                        value={skill}
                        checked={isChecked}
                        onChange={handleAdditionalSkillsChange}
                      />
                      <span className="ml-2">{skill}</span>
                    </Label>
                  );
                })}
              </div>

              {/* FINANCIAL DETAILS */}
              <h2 className="col-span-full text-lg font-semibold">
                Financial Details
              </h2>

              <div className="col-span-1">
                <Label>
                  <span>Bank Account Name</span>
                  <Input
                    name="bank_account_name"
                    type="text"
                    value={nurseProfile?.bank_account_name || ""}
                    onChange={handleChange}
                  />
                </Label>
              </div>

              <div className="col-span-1">
                <Label>
                  <span>Bank Account Sort Code</span>
                  <Input
                    name="bank_account_sort_code"
                    type="text"
                    value={nurseProfile?.bank_account_sort_code || ""}
                    onChange={handleChange}
                  />
                </Label>
              </div>

              <div className="col-span-1">
                <Label>
                  <span>Bank Account Number</span>
                  <Input
                    name="bank_account_number"
                    type="text"
                    value={nurseProfile?.bank_account_number || ""}
                    onChange={handleChange}
                  />
                </Label>
              </div>

              <div className="col-span-1">
                <Label>
                  <span>Onboarding Date</span>
                  <Input
                    name="sirkka_onboarding_date"
                    type="date"
                    value={nurseProfile?.sirkka_onboarding_date || ""}
                    disabled
                  />
                </Label>
              </div>

              <div className="col-span-1">
                <Label>
                  <span>First Person Rate</span>
                  <Input
                    name="first_person_rate"
                    type="number"
                    step="0.01"
                    value={nurseProfile?.first_person_rate || ""}
                    disabled
                  />
                </Label>
              </div>

              <div className="col-span-1">
                <Label>
                  <span>Additional Person Rate</span>
                  <Input
                    name="additional_person_rate"
                    type="number"
                    step="0.01"
                    value={nurseProfile?.additional_person_rate || ""}
                    disabled
                  />
                </Label>
              </div>

              <div className="col-span-1">
                <Label>
                  <span>Hourly Rate</span>
                  <Input
                    name="hourly_rate"
                    type="number"
                    step="0.01"
                    value={nurseProfile?.hourly_rate || ""}
                    disabled
                  />
                </Label>
              </div>

              <div className="col-span-1">
                <Label>
                  <span>Sunday Extra Rate (%)</span>
                  <Input
                    name="sunday_extra_rate_percent"
                    type="number"
                    step="0.01"
                    value={nurseProfile?.sunday_extra_rate_percent || ""}
                    disabled
                  />
                </Label>
              </div>
            </div>

            <div className="flex justify-center mt-5">
              <Button
                type="submit"
                className="!bg-blue-500 !hover:bg-blue-600 !text-white"
                disabled={loading}
              >
                {loading ? "Saving..." : "Save Profile"}
              </Button>
            </div>
          </form>
        </CardBody>
      </Collapse>
    </Card>
  );
}

export default NurseProfile;
