import { useState, useEffect } from "react";
import PageTitle from "components/Typography/PageTitle";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { Button, CardBody } from "@windmill/react-ui";

import { GroupTestingInvoiceForm } from "components/form/invoice/GroupTestingInvoice";
import { HomeVisitInvoiceForm } from "components/form/invoice/HomeVisitInvoice";
import { CustomInvoiceForm } from "components/form/invoice/CustomInvoice";
import { notifyError, notifySuccess } from "../../utils/toast";
import { postRequest } from "../../helpers/requests";

const initialGroupData = {
  invoiceJobDate: "",
  invoiceBusinessClientName: "SIRKKA",
  invoiceLocationPostcode: "",
  invoiceHourlyRate: "",
  invoiceTotalHours: "",
  invoiceTotalMiles: "",
};

const initialHomeVisitJob = {
  invoiceJobDate: "",
  invoiceBusinessClientName: "SIRKKA",
  invoiceLocationPostcode: "",
  invoiceBaseRate: "",
  invoicePatientNumber: "",
  invoiceAdditionalTestCharge: 10,
  invoiceTotalMiles: "",
};

const initialCustomJob = {
  invoiceJobDate: "",
  invoiceBusinessClientName: "SIRKKA",
  invoiceLocationPostcode: "",
  invoiceCustomPricing: "",
};

function CreateInvoiceForm() {
  // ------------------------------------------------------------------
  // 1) Load nurse profile from local storage (instead of personal form)
  // ------------------------------------------------------------------
  const [nurseProfile, setNurseProfile] = useState(null);

  useEffect(() => {
    const storedProfile = localStorage.getItem("nurse_profile");
    if (!storedProfile) {
      console.warn("No nurse_profile found in localStorage");
      notifyError(
        "We couldn't find your nurse profile. Please contact Sirkka to upload your personal details or log in again to continue."
      );
      setNurseProfile(null);
    } else {
      try {
        setNurseProfile(JSON.parse(storedProfile));
      } catch (error) {
        console.error("Error parsing nurse_profile from localStorage:", error);
        notifyError(
          "Your nurse profile appears to be invalid. Please Please contact Sirkka to upload your personal details or log in again."
        );
        setNurseProfile(null);
      }
    }
  }, []);  

  // ------------------------------------------------------------------
  // 2) Group Testing (Single Job)
  // ------------------------------------------------------------------
  const [groupData, setGroupData] = useState(initialGroupData);

  const handleGroupChange = (e) => {
    const { name, value } = e.target;
    setGroupData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmitGroup = async (e) => {
    e.preventDefault();
    notifySuccess("Please wait while we create your invoice...", 1000);

    // Build payload from nurseProfile + group data
    const payload = {
      // personal info from nurseProfile
      invoiceIssuerName: nurseProfile?.full_name || "",
      invoiceIssuerEmail: nurseProfile?.personal_emails || "",
      invoiceIssuerAddressLine1: nurseProfile?.address_line1 || "",
      invoiceIssuerAddressLine2: nurseProfile?.address_line2 || "",
      invoiceIssuerCity: nurseProfile?.city || "",
      invoiceIssuerPostcode: nurseProfile?.postcode || "",
      invoiceIssuerCountry: nurseProfile?.country || "",
      invoiceIssuerAccountName: nurseProfile?.bank_account_name || "",
      invoiceIssuerSortCode: nurseProfile?.bank_account_sort_code || "",
      invoiceIssuerAccountNumber: nurseProfile?.bank_account_number || "",

      ...groupData,
      invoiceType: "hourly",
    };

    await submitForm(payload);
  };

  // ------------------------------------------------------------------
  // 3) Home Visit (Multiple Jobs)
  // ------------------------------------------------------------------
  const [homeVisitJobs, setHomeVisitJobs] = useState([initialHomeVisitJob]);

  const handleHomeVisitChange = (index, e) => {
    const { name, value } = e.target;
    setHomeVisitJobs((prevJobs) => {
      const updated = [...prevJobs];
      updated[index] = { ...updated[index], [name]: value };
      return updated;
    });
  };

  const handleAddHomeVisitJob = () => {
    setHomeVisitJobs((prev) => [...prev, { ...initialHomeVisitJob }]);
  };

  const handleRemoveHomeVisitJob = (index) => {
    setHomeVisitJobs((prevJobs) => prevJobs.filter((_, i) => i !== index));
  };

  const handleSubmitHomeVisit = async (e) => {
    e.preventDefault();
    notifySuccess("Please wait while we create your invoice...", 1000);

    const payload = {
      invoiceIssuerName: nurseProfile?.full_name || "",
      invoiceIssuerEmail: nurseProfile?.personal_emails || "",
      invoiceIssuerAddressLine1: nurseProfile?.address_line1 || "",
      invoiceIssuerAddressLine2: nurseProfile?.address_line2 || "",
      invoiceIssuerCity: nurseProfile?.city || "",
      invoiceIssuerPostcode: nurseProfile?.postcode || "",
      invoiceIssuerCountry: nurseProfile?.country || "",
      invoiceIssuerAccountName: nurseProfile?.bank_account_name || "",
      invoiceIssuerSortCode: nurseProfile?.bank_account_sort_code || "",
      invoiceIssuerAccountNumber: nurseProfile?.bank_account_number || "",

      invoiceJobs: homeVisitJobs,
      invoiceType: "quantity",
    };

    await submitForm(payload);
  };

  // ------------------------------------------------------------------
  // 4) Custom Invoice (Multiple Jobs)
  // ------------------------------------------------------------------
  const [customJobs, setCustomJobs] = useState([initialCustomJob]);

  const handleCustomChange = (index, e) => {
    const { name, value } = e.target;
    setCustomJobs((prevJobs) => {
      const updated = [...prevJobs];
      updated[index] = { ...updated[index], [name]: value };
      return updated;
    });
  };

  const handleAddCustomJob = () => {
    setCustomJobs((prev) => [...prev, { ...initialCustomJob }]);
  };

  const handleRemoveCustomJob = (index) => {
    setCustomJobs((prevJobs) => prevJobs.filter((_, i) => i !== index));
  };

  const handleSubmitCustom = async (e) => {
    e.preventDefault();
    notifySuccess("Please wait while we create your invoice...", 1000);

    const payload = {
      invoiceIssuerName: nurseProfile?.full_name || "",
      invoiceIssuerEmail: nurseProfile?.personal_emails || "",
      invoiceIssuerAddressLine1: nurseProfile?.address_line1 || "",
      invoiceIssuerAddressLine2: nurseProfile?.address_line2 || "",
      invoiceIssuerCity: nurseProfile?.city || "",
      invoiceIssuerPostcode: nurseProfile?.postcode || "",
      invoiceIssuerCountry: nurseProfile?.country || "",
      invoiceIssuerAccountName: nurseProfile?.bank_account_name || "",
      invoiceIssuerSortCode: nurseProfile?.bank_account_sort_code || "",
      invoiceIssuerAccountNumber: nurseProfile?.bank_account_number || "",

      invoiceJobs: customJobs,
      invoiceType: "custom",
    };

    await submitForm(payload);
  };

  // ------------------------------------------------------------------
  // 5) Common form submission
  // ------------------------------------------------------------------
  const submitForm = async (payload) => {
    try {
      const response = await postRequest("invoices/create", payload, null, {
        parseResponse: false,
      });

      if (!response || !response.ok) {
        notifyError("Invoice creation failed, please try again!");
        return;
      }
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      const target = isSafari ? "_self" : "_blank";

      const blob = await response.blob();
      const file = window.URL.createObjectURL(blob);
      window.open(file, target);

      setGroupData(initialGroupData);
      setHomeVisitJobs([initialHomeVisitJob]);
      setCustomJobs([initialCustomJob]);
    } catch (err) {
      console.error(err);
      notifyError("Invoice creation failed, please try again!");
    }
  };

  // ------------------------------------------------------------------
  // 6) Collapsible Cards
  // ------------------------------------------------------------------
  const [openCardIndex, setOpenCardIndex] = useState(null);
  const handleCardToggle = (index) => {
    setOpenCardIndex(openCardIndex === index ? null : index);
  };

  return (
    <>
      <PageTitle>{"Create PDF Invoices"}</PageTitle>
      <Card>
        <CardBody>
          <p>
            <b>IMPORTANT</b>:
            <br />
            1. Please put the amount that you have agreed with Sirkka staff in
            the relevant invoice section. Sirkka staff follow the contracts that
            you have signed and/or apply discretions on a case by case basis.
            <br />
            2. Please ensure that you have completed the{" "}
            <a
              href="https://forms.office.com/pages/responsepage.aspx?id=teK2BhsA9kafJYS0elBAJATRdceoBrJMkG8eAcguCzhUOUtZMjdNS0tOVFQ0Q0FNN05GN0RHRk1ROS4u"
              target="_blank"
              rel="noreferrer"
              style={{ color: "blue", textDecoration: "underline" }}
            >
              <b>Phlebotemist Post-Session Feedback Form</b>
            </a>{" "}
            before submitting your invoice. We cannot process your invoice
            before receiving the completed form.
            <br />
            3. The invoice has 3 sections: Individual phlebotomy, Group
            Phlebotomy, Customised Fee. Please fill out the appropriate form
            with the correct information, click the generate pdf invoice button.
            A PDF of the invoice should appear in a new tab on your browser.
            <br />
            <b>Please check it carefully and email a copy to{" "}
            accounts@sirkkaltd.com</b>.
          </p>
        </CardBody>
      </Card>
      <br />

      {/* ---------------- GROUP TESTING (Single) ---------------- */}
      <Card className="min-w-0 shadow-xs overflow-hidden !bg-white mb-5">
        <CardHeader
          title="Form - Phlebotomy group event/locum (paid by hourly rate)"
          subheader="A testing involving 4+ customers counts as group testing"
          action={
            <IconButton onClick={() => handleCardToggle(0)} size="small">
              {openCardIndex === 0 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          }
        />
        <div style={{ backgroundColor: "rgba(211,211,211,0.4)" }}>
          <Collapse in={openCardIndex === 0} timeout="auto" unmountOnExit>
            <CardBody>
              <form id="group-testing-form" onSubmit={handleSubmitGroup}>
                <div className="grid gap-4 lg:grid-cols-3 py-2">
                  <GroupTestingInvoiceForm formData={groupData} handleChange={handleGroupChange} />
                </div>
                <div className="flex justify-center mt-3">
                  <Button type="submit">Generate Invoice PDF</Button>
                </div>
              </form>
            </CardBody>
          </Collapse>
        </div>
      </Card>

      {/* ---------------- HOME VISIT (Multiple) ---------------- */}
      <Card className="min-w-0 shadow-xs overflow-hidden bg-white mb-5">
        <CardHeader
          title="Form - Phlebotomy for individual home visits (1-3 customers)"
          subheader="This is for non-group testing, 1 to 3 customers in one session."
          action={
            <IconButton onClick={() => handleCardToggle(1)} size="small">
              {openCardIndex === 1 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          }
        />
        <div style={{ backgroundColor: "rgba(211,211,211,0.4)" }}>
          <Collapse in={openCardIndex === 1} timeout="auto" unmountOnExit>
            <CardBody>
              <form id="individual-form" onSubmit={handleSubmitHomeVisit}>
                {homeVisitJobs.map((job, index) => (
                  <div key={index} className="p-4 mb-4 border-b border-gray-300">
                    <h2 className="font-semibold text-blue-600 mb-2">
                      Home Visit Job {index + 1}
                    </h2>
                    <div className="grid gap-4 lg:grid-cols-3 py-2">
                      <HomeVisitInvoiceForm formData={job} handleChange={(e) => handleHomeVisitChange(index, e)} />
                    </div>
                    <div className="text-center justify-center mt-3">
                      {homeVisitJobs.length > 1 && (
                        <Button
                          type="button"
                          className="!bg-red-500 !hover:bg-red-600 !text-white w-1/5"
                          onClick={() => handleRemoveHomeVisitJob(index)}
                        >
                          Remove Job
                        </Button>
                      )}
                    </div>
                  </div>
                ))}
                <div className="flex justify-center mt-3">
                  <Button
                    type="button"
                    onClick={handleAddHomeVisitJob}
                    className="!bg-blue-500 !hover:bg-blue-600 !text-white w-1/5"
                  >
                    + Add Another Job
                  </Button>
                </div>
                <div className="flex justify-center mt-3">
                  <Button type="submit" className="w-1/5">
                    Generate Invoice PDF
                  </Button>
                </div>
              </form>
            </CardBody>
          </Collapse>
        </div>
      </Card>

      {/* ---------------- CUSTOM (Multiple) ---------------- */}
      <Card className="min-w-0 shadow-xs overflow-hidden bg-white mb-5">
        <CardHeader
          title="Form - Case-by-case agreed payment terms"
          action={
            <IconButton onClick={() => handleCardToggle(2)} size="small">
              {openCardIndex === 2 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          }
        />
        <div style={{ backgroundColor: "rgba(211,211,211,0.4)" }}>
          <Collapse in={openCardIndex === 2} timeout="auto" unmountOnExit>
            <CardBody>
              <form id="custom-invoice" onSubmit={handleSubmitCustom}>
                {customJobs.map((job, index) => (
                  <div key={index} className="p-4 mb-4 border-b border-gray-300">
                    <h2 className="font-semibold text-blue-600 mb-2">
                      Custom Job {index + 1}
                    </h2>
                    <div className="grid gap-4 lg:grid-cols-3 py-2">
                      <CustomInvoiceForm formData={job} handleChange={(e) => handleCustomChange(index, e)} />
                    </div>
                    <div className="text-center justify-center mt-3">
                      {customJobs.length > 1 && (
                        <Button
                          type="button"
                          className="!bg-red-500 !hover:bg-red-600 !text-white w-1/5"
                          onClick={() => handleRemoveCustomJob(index)}
                        >
                          Remove Job
                        </Button>
                      )}
                    </div>
                  </div>
                ))}
                <div className="flex justify-center mt-3">
                  <Button
                    type="button"
                    onClick={handleAddCustomJob}
                    className="!bg-blue-500 !hover:bg-blue-600 !text-white w-1/5"
                  >
                    + Add Another Job
                  </Button>
                </div>
                <div className="flex justify-center mt-3">
                  <Button type="submit" className="w-1/5">
                    Generate Invoice PDF
                  </Button>
                </div>
              </form>
            </CardBody>
          </Collapse>
        </div>
      </Card>
      <br />
    </>
  );
}

export default CreateInvoiceForm;
