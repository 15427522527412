import { redirect } from "react-router-dom";
import { postRequest, getRequest } from "./requests";

const login = async ({ email, password }) => {
  const response = await postRequest("auth/login", { email, password });
  if (response.status === "success") {
    localStorage.setItem("email", email);
    localStorage.setItem("user_metadata", JSON.stringify(response.user));
    localStorage.setItem("permissions", JSON.stringify(response.access));

    if (response.user.role_id === 2) {
      try {
        const nurseRes = await getRequest(`nurse-profile/${response.user.user_id}`);
        if (nurseRes?.data) {
          localStorage.setItem("nurse_profile", JSON.stringify(nurseRes.data));
        }
      } catch (err) {
        console.error("Error loading nurse profile at login:", err);
      }
    }

    return Promise.resolve();
  }
  return Promise.reject();
};

const logout = () => {
  localStorage.removeItem("email");
  localStorage.removeItem("user_metadata");
  localStorage.removeItem("permissions");
  localStorage.removeItem("nurse_profile");
  redirect("/login");
  return Promise.resolve();
};

const checkError = ({ status }) => {
  if (status === 401 || status === 403) {
    localStorage.removeItem("email");
    localStorage.removeItem("user_metadata");
    localStorage.removeItem("permissions");
    localStorage.removeItem("nurse_profile");
    return Promise.reject();
  }
  return Promise.resolve();
};

const checkAuth = () => {
  return localStorage.getItem("email") ? Promise.resolve() : Promise.reject();
};

const getPermissions = () => {
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  return Promise.resolve(permissions);
};

const authProvider = {
  login,
  logout,
  checkError,
  checkAuth,
  getPermissions,
};

export default authProvider;
