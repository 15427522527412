import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
} from "@mui/material";
import { patchRequest } from "../../helpers/requests";
import LocationsList from "components/LocationsList";
import { convertUnderscoresToCamelCase } from "../../helpers/convertToCamelCase";
import AssociatedLocationsSelector from "./AssociatadLocationsSelector";

const ServiceEditModal = ({ open, handleClose, service, entity }) => {
  const [formData, setFormData] = useState({});
  const [initialData, setInitialData] = useState({});
  const [tags, setTags] = useState([]);
  const [selectedServiceTypes, setSelectedServiceTypes] = useState("");

  const parseArrayString = (str) => {
    try {
      return JSON.parse(str.replace(/'/g, '"'));
    } catch (e) {
      return [];
    }
  };

  useEffect(() => {
    if (service) {
      const convertedService = {
        ...service,
        service_price: (service.service_price / 100).toFixed(2),
        tags: Array.isArray(service.tags) ? service.tags : parseArrayString(service.tags),
        bullets: Array.isArray(service.bullets)
          ? service.bullets.join("\n")
          : parseArrayString(service.bullets).join("\n"),
        first_description: Array.isArray(service.first_description)
          ? service.first_description.join("\n")
          : parseArrayString(service.first_description).join("\n"),
        description_continued: Array.isArray(service.description_continued)
          ? service.description_continued.join("\n")
          : parseArrayString(service.description_continued).join("\n"),
        disclaimers: Array.isArray(service.disclaimers)
          ? service.disclaimers.join("\n")
          : parseArrayString(service.disclaimers).join("\n"),
        service_type: Array.isArray(service.service_type) ? service.service_type.join(", ") : service.service_type,
      };

      setFormData(convertedService);
      setInitialData(convertedService);
      setTags(Array.isArray(service.tags) ? service.tags : parseArrayString(service.tags));
      setSelectedServiceTypes(convertedService.service_type);
    }
  }, [service]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: checked }));
  };

  const handleTagDelete = (tagToDelete) => () => {
    setTags((tags) => tags.filter((tag) => tag !== tagToDelete));
  };

  const handleTagAdd = (event) => {
    if (event.key === "Enter" && event.target.value) {
      event.preventDefault();
      setTags((prevTags) => [...prevTags, event.target.value]);
      event.target.value = "";
    }
  };

  const handleServiceTypeChange = (event) => {
    setSelectedServiceTypes(event.target.value.join(", "));
  };

  const handleServiceTypeDelete = (typeToDelete) => (event) => {
    event.stopPropagation();
    const updatedServiceTypes = selectedServiceTypes
      .split(", ")
      .filter((type) => type !== typeToDelete)
      .join(", ");
    setSelectedServiceTypes(updatedServiceTypes);
  };

  const arraysEqual = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  };

  const convertFormDataToCamelCase = (data) => {
    const convertedData = {};
    for (const key in data) {
      convertedData[convertUnderscoresToCamelCase(key)] = data[key];
    }
    return convertedData;
  };

  const handleSaveChanges = async () => {
    const changedData = Object.keys(formData).reduce((acc, key) => {
      if (formData[key] !== initialData[key]) {
        if (key === "service_price") {
          acc[key] = Math.round(parseFloat(formData[key]) * 100);
        } else if (
          key === "bullets" ||
          key === "first_description" ||
          key === "description_continued" ||
          key === "disclaimers"
        ) {
          acc[key] = formData[key].split("\n");
        } else {
          acc[key] = formData[key];
        }
      }
      return acc;
    }, {});

    if (!arraysEqual(tags, initialData.tags)) {
      changedData.tags = tags;
    }

    if (selectedServiceTypes !== initialData.service_type) {
      changedData.service_type = selectedServiceTypes;
    }

    try {
      const camelCaseData = convertFormDataToCamelCase(changedData);
      const response = await patchRequest(`products/${service.service_id}`, camelCaseData);
      if (response && response.status === "success") {
        console.log("Service updated successfully");
        handleClose();
      } else {
        console.error("Failed to update service");
      }
    } catch (error) {
      console.error("Error updating service:", error);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflow: "auto",
          width: {
            xs: "90%",
            sm: "90%",
            md: "90%",
            lg: "1200px",
            xl: "1200px",
          },
          maxWidth: "100%",
          maxHeight: "90vh",
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom sx={{ fontWeight: 600 }}>
          Edit Service
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.service_status || false}
                  onChange={handleCheckboxChange}
                  name="service_status"
                />
              }
              label="Service Active"
              helperText="Check if the service is currently active"
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.enable_discount || false}
                  onChange={handleCheckboxChange}
                  name="enable_discount"
                />
              }
              label="Discount Allowed"
              helperText="Check if discounts are allowed for this service"
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.appt_stackable || false}
                  onChange={handleCheckboxChange}
                  name="appt_stackable"
                />
              }
              label="Appointment Stackable"
              helperText="Check if appointments for this service can be stacked in the same timeslot"
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              control={
                <Checkbox checked={formData.bookable || false} onChange={handleCheckboxChange} name="bookable" />
              }
              label="Bookable"
              helperText="Check if this service can be booked"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Service Name"
              name="service_name"
              value={formData.service_name || ""}
              onChange={handleChange}
              helperText="The name of the service"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="service-type-label">Service Type</InputLabel>
              <Select
                labelId="service-type-label"
                multiple
                value={selectedServiceTypes.split(", ")}
                onChange={handleServiceTypeChange}
                label="Service Type"
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        onDelete={handleServiceTypeDelete(value)}
                        sx={{ mb: 1 }}
                        onMouseDown={(event) => event.stopPropagation()} // Prevent the dropdown from opening
                      />
                    ))}
                  </Box>
                )}
              >
                {["In-Clinic", "Virtual", "Home Visit", "Postal"].map((type) => (
                  <MenuItem key={type} value={type}>
                    <Checkbox checked={selectedServiceTypes.split(", ").indexOf(type) > -1} />
                    <ListItemText primary={type} />
                  </MenuItem>
                ))}
              </Select>
              <Typography variant="caption" color="textSecondary">
                Select one or more service types
              </Typography>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Description"
              name="description"
              value={formData.description || ""}
              onChange={handleChange}
              multiline
              rows={4}
              helperText="Displayed on the list page for the Wellbeing section, this description will NOT appear on the International Treatment page."
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Service Price"
              name="service_price"
              value={formData.service_price || ""}
              onChange={handleChange}
              InputProps={{
                startAdornment: <InputAdornment position="start">£</InputAdornment>,
              }}
              helperText="The price in pounds and pence, for example £129.99"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Appointment Duration"
              name="appt_duration"
              value={formData.appt_duration || ""}
              onChange={handleChange}
              helperText="Enter the duration of the appointment in minutes"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Post Appointment Buffer"
              name="post_appt_buffer"
              value={formData.post_appt_buffer || ""}
              onChange={handleChange}
              helperText="Enter the buffer time after the appointment in minutes"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Service Code"
              name="service_code"
              value={formData.service_code || ""}
              onChange={handleChange}
              helperText="This must be a unique code in camelCase of the service name (no spaces)"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Service Icon"
              name="service_icon"
              value={formData.service_icon || ""}
              onChange={handleChange}
              helperText="The file name of an existing icon - use 'health_screening' for a generic icon"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Bullets"
              name="bullets"
              value={formData.bullets || ""}
              onChange={handleChange}
              multiline
              rows={4}
              helperText="Shown after the description on the service pop-up. New line = new paragraph."
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="First Description"
              name="first_description"
              value={formData.first_description || ""}
              onChange={handleChange}
              multiline
              rows={4}
              helperText="Shown on the service pop-up when a user selects the service. Press enter to start a new paragraph."
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Description Continued"
              name="description_continued"
              value={formData.description_continued || ""}
              onChange={handleChange}
              multiline
              rows={4}
              helperText="Optionally show a second paragraph after the bullets on the service pop-up. Press enter to start a new paragraph."
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Display Order"
              name="display_order"
              value={formData.display_order || ""}
              onChange={handleChange}
              helperText="Override with a higher number than other entities, or leave empty for default order"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Email Template"
              name="email_template"
              value={formData.email_template || ""}
              onChange={handleChange}
              helperText="Enter the email template (Ask devs if unsure)"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Questionnaire Link"
              name="questionnaire_link"
              value={formData.questionnaire_link || ""}
              onChange={handleChange}
              helperText="Optionally enter a link if this service does not go to checkout"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Tags"
              onKeyDown={handleTagAdd}
              helperText="Press Enter after each tag you input. For example, Wellbeing (enter) International Treatments (enter)."
            />
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, mt: 1 }}>
              {tags.map((tag) => (
                <Chip key={tag} label={tag} onDelete={handleTagDelete(tag)} />
              ))}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Disclaimers"
              name="disclaimers"
              value={formData.disclaimers || ""}
              onChange={handleChange}
              multiline
              rows={4}
              helperText="Disclaimers are shown in a smaller font at the end of the service pop-up. Press enter to start a new paragraph."
            />
          </Grid>
          <Grid item xs={12}>
            <AssociatedLocationsSelector serviceId={service.service_id} entityId={entity.booking_entity_id} />
          </Grid>
        </Grid>
        <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="caption" display="block">
              Created At: {new Date(service.created_at).toLocaleString()}
            </Typography>
            <Typography variant="caption" display="block">
              Updated At: {new Date(service.updated_at).toLocaleString()}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Button onClick={handleClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleSaveChanges}>
              Save Changes
            </Button>
          </Box>
        </Box>

        {/* <hr style={{ margin: "30px 0 0 0" }} />
        <LocationsList service={service} entity={entity} /> */}
      </Box>
    </Modal>
  );
};

export default ServiceEditModal;
