import React, { useState, useEffect } from "react";
import { Label, Input, Select } from "@windmill/react-ui";

// This regex allows:
//   1) integers: "123"
//   2) one decimal place: "12.3"
//   3) two decimal places: "3.14"
const validPriceRegex = /^\d+(\.\d{0,2})?$/;

export const CustomInvoiceForm = ({ formData, handleChange }) => {
  const [lastValidPrice, setLastValidPrice] = useState("");
  useEffect(() => {
    if (formData.invoiceCustomPricing) {
      setLastValidPrice(formData.invoiceCustomPricing);
    }
  }, [formData.invoiceCustomPricing]);

  const handlePriceChange = (e) => {
    let newValue = e.target.value;
    if (newValue === "") {
      setLastValidPrice("");
      handleChange(e);
      return;
    }

    if (validPriceRegex.test(newValue) && parseFloat(newValue) >= 0) {
      setLastValidPrice(newValue);
      handleChange(e);
    } else {
      e.target.value = lastValidPrice;
    }
  };

  return (
    <>
      <div>
        <Label htmlFor="invoiceJobDate">Date of the Job:</Label>
        <Input
          type="date"
          id="invoiceJobDate"
          name="invoiceJobDate"
          value={formData.invoiceJobDate}
          onChange={handleChange}
          style={{ backgroundColor: "white" }}
        />
      </div>

      <div>
        <Label htmlFor="invoiceBusinessClientName">
          <span>Business Client Name: </span>
          <span className="text-xs text-gray-500">(select SIRKKA if unsure)</span>
        </Label>
        <Select
          id="invoiceBusinessClientName"
          name="invoiceBusinessClientName"
          value={formData.invoiceBusinessClientName}
          onChange={handleChange}
          style={{ backgroundColor: "white" }}
        >
          <option value="SIRKKA">SIRKKA</option>
          <option value="AMS">AMS</option>
          <option value="CCL">CCL</option>
          <option value="CFMH">CFMH</option>
          <option value="CHAPS">CHAPS</option>
          <option value="SBL">SBL</option>
          <option value="TDL">TDL</option>
          <option value="USTR">USTR</option>
        </Select>
      </div>

      <div>
        <Label htmlFor="invoiceLocationPostcode">Location Full Post Code:</Label>
        <Input
          type="text"
          id="invoiceLocationPostcode"
          name="invoiceLocationPostcode"
          value={formData.invoiceLocationPostcode}
          onChange={handleChange}
          style={{ backgroundColor: "white" }}
        />
      </div>

      <div>
        <Label htmlFor="invoiceCustomPricing">
          Agreed Custom Price (Include Travel):
        </Label>
        <Input
          type="text"
          id="invoiceCustomPricing"
          name="invoiceCustomPricing"
          value={formData.invoiceCustomPricing}
          onChange={handlePriceChange}
          placeholder="Please Enter Number only"
        />
      </div>
    </>
  );
};
