import React, { useEffect } from "react";
import { Label, Input, Select } from "@windmill/react-ui";

// This regex allows:
//   1) integers: "123"
//   2) one decimal place: "12.3"
//   3) two decimal places: "3.14"
const validPriceRegex = /^\d+(\.\d{0,2})?$/;

export const GroupTestingInvoiceForm = ({ formData, handleChange }) => {
  useEffect(() => {
    let fetchedHourlyRate = 12.5;
    const storedProfile = localStorage.getItem("nurse_profile");
    if (storedProfile) {
      try {
        const parsedProfile = JSON.parse(storedProfile);
        if (parsedProfile?.hourly_rate) {
          fetchedHourlyRate = parsedProfile.hourly_rate;
        }
      } catch (err) {
        console.error("Error parsing nurse_profile from localStorage:", err);
      }
    }

    handleChange({
      target: {
        name: "invoiceHourlyRate",
        value: fetchedHourlyRate,
      },
    });
  }, []);

  return (
    <>
      <div>
        <Label htmlFor="invoiceJobDate">Date of the Job:</Label>
        <Input
          type="date"
          id="invoiceJobDate"
          name="invoiceJobDate"
          value={formData.invoiceJobDate}
          onChange={handleChange}
          style={{ backgroundColor: "white" }}
        />
      </div>

      <div>
        <Label htmlFor="invoiceBusinessClientName">
          <span>Business Client Name: </span>
          <span className="text-xs text-gray-500">(select SIRKKA if unsure)</span>
        </Label>
        <Select
          id="invoiceBusinessClientName"
          name="invoiceBusinessClientName"
          value={formData.invoiceBusinessClientName}
          onChange={handleChange}
          style={{ backgroundColor: "white" }}
        >
          <option value="SIRKKA">SIRKKA</option>
          <option value="AMS">AMS</option>
          <option value="CCL">CCL</option>
          <option value="CFMH">CFMH</option>
          <option value="CHAPS">CHAPS</option>
          <option value="SBL">SBL</option>
          <option value="TDL">TDL</option>
          <option value="USTR">USTR</option>
        </Select>
      </div>

      <div>
        <Label htmlFor="invoiceLocationPostcode">Location Full Post Code:</Label>
        <Input
          type="text"
          id="invoiceLocationPostcode"
          name="invoiceLocationPostcode"
          value={formData.invoiceLocationPostcode}
          onChange={handleChange}
          style={{ backgroundColor: "white" }}
        />
      </div>
      <div>
        <Label htmlFor="invoiceTotalHours">Total Hours:</Label>
        <Input
          type="number"
          id="invoiceTotalHours"
          name="invoiceTotalHours"
          value={formData.invoiceTotalHours}
          onChange={handleChange}
          style={{ backgroundColor: "white" }}
        />
      </div>

      <div>
        <Label htmlFor="invoiceTotalMiles">Total Miles (return journeys):</Label>
        <Input
          type="number"
          id="invoiceTotalMiles"
          name="invoiceTotalMiles"
          value={formData.invoiceTotalMiles}
          onChange={handleChange}
          style={{ backgroundColor: "white" }}
        />
      </div>
    </>
  );
};
