import { patchRequest, postRequest } from "../helpers/requests";

const createTimeBlocks = async (data) => {
  return await postRequest("availabilities", data);
};

const deleteAvailabilitySlot = async (id, data) => {
  return await patchRequest(`availabilities/${id}/delete`, data);
};

const updateAvailabilitySlot = async (id, data) => {
  return await patchRequest(`availabilities/${id}/update`, data);
};

export { createTimeBlocks, deleteAvailabilitySlot, updateAvailabilitySlot };
